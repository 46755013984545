<template>
  <div></div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import Storage from "@/modules/storage";

export default {
  name: "EmailConfirmation",
  mounted() {
    Swal.fire({
      icon: "warning",
      title: "Validando su Email",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      stopKeydownPropagation: true,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
        setInterval(() => {
          const content = Swal.getContent();
          if (content) {
            const b = content.querySelector("b");
            if (b) {
              b.textContent = Swal.getTimerLeft();
            }
          }
        }, 100);
      }
    });

    const URL = new Storage().baseURL + "/auth/user/changeEmail";
    const sendData = [
      {
        code: this.$route.params.code,
        _id: localStorage.getItem("user._id")
      }
    ];
    axios
      .post(URL, sendData, {
        withCredentials: true
      })
      .then(response => {
        if (response.status === 200) {
          localStorage.setItem("user.email", response.data.body[0].email);
          Swal.fire({
            icon: "success",
            title: "Email actualizado",
            text: "Su Email se ha actualizado a: " + response.data.body[0].email
          }).then(() => {
            this.$router.push({ name: "Home" });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "No se ha actualizado el Email",
            text:
              "El código de verificación no es válido o ha expirado, por favor inténtelo nuevamente"
          }).then(() => {
            this.$router.push({ name: "Settings Basic" });
          });
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: "No se ha actualizado el Email",
            text:
              "El código de verificación no es válido o ha expirado, por favor inténtelo nuevamente"
          }).then(() => {
            this.$router.push({ name: "Settings Basic" });
          });
        } else if (error.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: "No se ha actualizado el Email",
            text:
              "El Email ya se encuentra asociada a otra cuenta, por favor inténtelo nuevamente"
          }).then(() => {
            this.$router.push({ name: "Settings Basic" });
          });
        }
      });
  }
};
</script>
